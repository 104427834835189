<template>
  <v-card class="mb-3 px-3">
    <v-card-title>자주 이체하는 계좌 목록</v-card-title>
    <v-card-text>
      <v-btn
        block
        large
        v-for="(route, index) in routes"
        :key="index"
        :disabled="isLoading"
        :color="$colors['f-white-blue-4']"
        elevation="0"
        class="primary--text font-weight-bold mb-2"
        @click="selectRoute(route)"
      >
        {{ route.from.label }}
        <v-icon class="mx-2" small>mdi-arrow-right-bold</v-icon>
        {{ route.to.label }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    fromAccount: {
      type: Object,
      default: (_) => {
        return null
      },
    },
    toAccount: {
      type: Object,
      default: (_) => {
        return null
      },
    },
  },
  data() {
    return {
      isLoading: false,
      routes: [
        {
          from: {
            userIdx: 2030,
            idx: 3692,
            label: '예수금 계좌',
          },
          to: {
            userIdx: 57,
            idx: 30048,
            label: '머천트 (우회 이체)',
          },
        },
        {
          from: {
            userIdx: 1,
            idx: 1,
            label: '머천트',
          },
          to: {
            userIdx: 2030,
            idx: 3692,
            label: '예수금 계좌',
          },
        },
      ],
    }
  },
  methods: {
    async selectRoute(route) {
      if (this.isLoading === true) {
        return
      }

      try {
        this.isLoading = true

        await Promise.all([
          this.getUserVirtualAccount(route.from.userIdx, route.from.idx, true),
          this.getUserVirtualAccount(route.to.userIdx, route.to.idx, false),
        ])
      } finally {
        this.isLoading = false
      }
    },
    async getUserVirtualAccount(userIdx, userVirtualAccountIdx, isFromAccount) {
      const { data } = await this.$paymentApi.getUserVirtualAccount(
        userVirtualAccountIdx
      )
      this.$emit(`update:${isFromAccount ? 'from' : 'to'}Account`, data)
    },
  },
}
</script>
